import React from "react"
import styles from "./nav.module.scss"

class ScrollBar extends React.Component {


  render() {
        return <div className={styles.scrollbarWrapper + " "+ (this.props.color ? this.props.color : "") }>
                {this.props.index? null : <div className={styles.text}>Swipe</div>}
                <div className={styles.bar} style={{ left: (20 * this.props.index) + "%" }}/>
                <div className={styles.scrollbar}/>
                <div className={styles.progress}>
                    <span>{this.props.index} | 4</span>
                </div>
            </div>
    }
}
export default ScrollBar;