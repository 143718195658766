import React from "react"
import styles from "./nav.module.scss"

function Nav(props) {
  return (
    <nav className={styles.nav + " " + props.color}>
      {props.children}
    </nav>
  )
}
export default Nav