import React, { useEffect, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "animate.css"
import "../styles/root.scss"
//import "../styles/index.scss"
import Section from "../components/index/section"
import BildFlo from "../images/index/DSC_9408.jpg"
import stylesnav from "../components/index/nav/nav.module.scss"
import BildBrandon from "../images/photography/Brandon/DSC_4404-2.jpg"
import BildMarina from "../images/photography/Marina/DSC_9517.jpg"
import ChevronButton from "../components/index/Chevron-Button"
import styles from "../components/index/section.module.scss"
import Typewriter from "typewriter-effect"
import HeroButton from "../components/hero-button"
import BildWork from "../images/work/Work-dekstop.jpg"
import BildWorkMobile from "../images/work/Work-Mobile.jpg"
import Nav from "../components/index/nav/nav"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisH, faHeartBroken, faTimes } from "@fortawesome/free-solid-svg-icons"
import { faHeart } from "@fortawesome/free-regular-svg-icons"
import AnimatedVisibility from "../components/index/nav/AnimatedContainer"
import SocialMedia from "../components/socialMedia"
import SwipeableViews from "react-swipeable-views"
import ScrollBar from "../components/index/nav/scroll-bar"
import sidenav from "../components/nav/sidenav.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Col } from "react-bootstrap"
import Row from "react-bootstrap/Row"
import SEO from "../components/seo"

function Index() {
  const [Index, SetIndex] = useState(0)
  const [color, SetColor] = useState(stylesnav.black)
  const [backcolor, SetBackColor] = useState(color)
  const Swipe = () => {
      if(cursor === "left"){
          if(Index-1 >= 0){
              SetIndex(Index-1)
              changeColor(Index-1)
          }
      }else{
          if(Index+1 < sections.length){
              SetIndex(Index+1)
              changeColor(Index+1)
          }
      }
  }
  const [navIsOpen, setNavOpen] = useState(false)
  const [socialIsOpen, setSocialIsOpen] = useState(false)
  const [cursor, SetCursor] = useState("left")
  const data = useStaticQuery(graphql`
{
  IndexMobile: file(relativePath: {eq: "index/flo_instagram_pb_CP.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 1024, quality: 60) {
        ...GatsbyImageSharpFluid
      }
    }
  }
   Index : file(relativePath: {eq: "index/unbenannt-3.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 3080, quality: 70) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  PhotographyMobileImage: file(relativePath: {eq: "index/DSC_4404-2.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 1024, quality: 60) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  PhotographyDesktopImage: file(relativePath: {eq: "photography/Marina/DSC_9517.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 3080, quality: 70) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  WorkMobileImage: file(relativePath: {eq: "index/Work-Mobile.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 1024, quality: 70) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  WorkDesktopImage: file(relativePath: {eq: "index/Work-dekstop.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 3080, quality: 70) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  AboutMeMobileImage: file(relativePath: {eq: ""}) {
    childImageSharp {
      fluid(maxWidth: 3080, quality: 70) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  AboutMeDesktopImage: file(relativePath: {eq: ""}) {
    childImageSharp {
      fluid(maxWidth: 3080, quality: 70) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  ContactMobileImage: file(relativePath: {eq: ""}) {
    childImageSharp {
      fluid(maxWidth: 3080, quality: 70) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  ContactDesktopImage: file(relativePath: {eq: ""}) {
    childImageSharp {
      fluid(maxWidth: 3080, quality: 70) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
  `)
  const imagesIndex = [
    data.IndexMobile.childImageSharp.fluid,
    {
      ...data.Index.childImageSharp.fluid,
      media: `(min-width: 1024px)`,
    },
  ]
  const imagesPhotography = [
    data.PhotographyMobileImage.childImageSharp.fluid,
    {
      ...data.PhotographyDesktopImage.childImageSharp.fluid,
      media: `(min-width: 1024px)`,
    },
  ]
  const imagesWork = [
    data.WorkMobileImage.childImageSharp.fluid,
    {
      ...data.WorkDesktopImage.childImageSharp.fluid,
      media: `(min-width: 1024px)`,
    },
  ]
  const sections = [
    <Section color={stylesnav.black}>
      <Row>
        <Col lg={12} xl={6} className={"p-0"}><Img className={styles.IndexImg} fluid={imagesIndex} objectFit="cover"
                                    objectPosition="50% 50%"/></Col>
        <Col lg={12} xl={6} className={"p-0 d-flex align-items-center justify-content-center "+styles.IndexCol}><Typewriter
          options={{
            autoStart: true,
            loop: true,
          }}
          onInit={(typewriter) => {
            typewriter.typeString("Hey, i am Florian")
              .pauseFor(1000)
              .deleteChars(7)
              .typeString("Developer")
              .pauseFor(1000)
              .deleteChars(9)
              .typeString("Photographer")
              .pauseFor(1000)
              .start()
          }}
        /></Col>
      </Row>
      <div className={styles.swipe} onClick={() => Swipe()}/>
    </Section>,
    <Section color={stylesnav.white}>
      <Img className={styles.Img} fluid={imagesPhotography} />
      <div className={styles.swipe} onClick={() => Swipe()}/>
      <div className={styles.inner + " " + styles.white}>
        <h1 className="display-2">Photography</h1>
        <div className={styles.desktop}>
          <h1>View my portraits and photography projects</h1>
          <a href={"/photography"}><HeroButton>More Pictures</HeroButton></a>
        </div>
        <ChevronButton link="/photography"/>
      </div>
    </Section>,
    <Section color={stylesnav.black}>
      <Img className={styles.Img} fluid={imagesWork} />
      <div className={styles.swipe} onClick={() => Swipe()}/>
      <div className={styles.inner + " " + styles.black}>
        <h1 className="display-2">Print & Web</h1>
        <div className={styles.desktop}>
          <h1>View my portraits and photography projects</h1>
          <a href={"/work"}><HeroButton>All Projects</HeroButton></a>
        </div>
        <ChevronButton link="/work"/>
      </div>
    </Section>,
    <Section color={stylesnav.black}>
        <div className={styles.swipe} onClick={() => Swipe()}/>
      <div className={styles.inner + " " + styles.black}>
        <h1 className="display-2">Skills</h1>
        <ChevronButton link="/skills"/>
      </div>
    </Section>,
    <Section color={stylesnav.black}>
        <div className={styles.swipe} onClick={() => Swipe()}/>
      <div className={styles.inner + " " + styles.black}>
        <h1 className="display-2">Contact</h1>
        <ChevronButton link="/contact"/>
      </div>
    </Section>
  ]


  function updateWindowDimensions() {
    document.documentElement.style.setProperty("--vh", `${(window.innerHeight * 0.01)}px`)
  }

  useEffect(() => {
    var ua = window.navigator.userAgent
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
    var webkit = !!ua.match(/WebKit/i)
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i)
    if (iOSSafari) {
      window.addEventListener("resize", updateWindowDimensions)
      window.addEventListener("scroll", updateWindowDimensions)
      window.addEventListener("scroll", closeNavScroll)
    }
    document.onmousemove = handleMouseMove
  })
    function closeNavScroll() {
        if(navIsOpen && window.pageYOffset > 150){
          SetBackColor(color);
          SetColor(backcolor);
          setSocialIsOpen(false);
          setNavOpen(false);
        }
    }
    function toggleNav() {
        if(navIsOpen){
            SetBackColor(color);
            SetColor(backcolor);
        }
        else{
            SetBackColor(color);
            SetColor(stylesnav.white);

        }
        setSocialIsOpen(false);
        setNavOpen(!navIsOpen);
    }

    function toggleSidebar() {
        if(socialIsOpen){
            SetBackColor(color);
            SetColor(backcolor);
        }
        else{
            SetBackColor(color);
            SetColor(stylesnav.white);
        }
        setNavOpen(false);
        setSocialIsOpen(!socialIsOpen);
    }

    function handleMouseMove(event) {
        var eventDoc, doc, body;
        event = event || window.event;
        console.log(event)
        if (event.pageX == null && event.clientX != null) {
            eventDoc = (event.target && event.target.ownerDocument) || document;
            doc = eventDoc.documentElement;
            body = eventDoc.body;

            event.pageX = event.clientX +
              (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
              (doc && doc.clientLeft || body && body.clientLeft || 0);
            event.pageY = event.clientY +
              (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
              (doc && doc.clientTop  || body && body.clientTop  || 0 );
        }
        if(event.pageX < window.innerWidth/2){
            SetCursor("left")
        }
        else{
            SetCursor("right")
        }
    }
    function getCursoStyle(index){
        if(sections[index].props.color === stylesnav.white){
            if(cursor === "left"){
                return styles.whiteLeft
            }else{
                return styles.whiteRight
            }
        }
        else{
            if(cursor === "right"){
                return styles.blackRight
            }else{
                return styles.blackLeft
            }
        }
    }
    function handleChangeIndex(index) {
        SetIndex(index)
        changeColor(index)
    }
    function changeColor(index){
        if(sections[index].props.color === stylesnav.white){
            SetBackColor(color)
            SetColor(stylesnav.white)
        }
        else{
            SetBackColor(color)
            SetColor(stylesnav.black)
        }
    }

  return (
    <>
      <SEO title="Home" keywords={[`Florian Parfuss`]}  />
        <Nav color={color}>
            <div onClick={() =>toggleSidebar()} className={stylesnav.socialMediaContainer}>
                {socialIsOpen ? <FontAwesomeIcon icon={faHeartBroken} size={"lg"}/> : <FontAwesomeIcon icon={faHeart} size={"lg"}/>}
            </div>
            <a>Florian</a>
            <div onClick={() =>toggleNav()} className={stylesnav.socialMediaContainer}>
                {navIsOpen ? <FontAwesomeIcon icon={faTimes} size={"lg"}/> : <FontAwesomeIcon icon={faEllipsisH} size={"lg"}/>}
            </div>
        </Nav>
        <AnimatedVisibility visible={navIsOpen}>
            <div id={sidenav.links} className={sidenav.wrapper}>
                <a href={"./photography"}>photography</a>
                <a href={"./work"}>work</a>
                <a href={"./skills"}>Skills</a>
                <a href={"./contact"}>contact</a>
            </div>
        </AnimatedVisibility>
        <AnimatedVisibility visible={socialIsOpen}>
            <SocialMedia/>
        </AnimatedVisibility>
        <SwipeableViews index={Index} onChangeIndex={handleChangeIndex} indexenableMouseEvents>
            {sections.map((section, index) =>
              <div className={styles.slide + " "+ getCursoStyle(index)}>{section}</div>
            )}
        </SwipeableViews>
        <ScrollBar color={color} index={Index}/>
    </>
  )
}

export default Index
