import React from "react"
import styles from "./index/nav/nav.module.scss"
import { faInstagram,faFacebook,faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Col, Row } from "react-bootstrap"

export default (props) => (
  <Row className={styles.socialMedia}>
    <Col md={4} className="d-flex justify-content-center align-items-center">
      <a href="https://www.facebook.com/florian.ela.1" target="_blank"><FontAwesomeIcon icon={faInstagram} size={"9x"}/></a>
    </Col>
    <Col md={4} className=" d-flex justify-content-center align-items-center">
      <a href="https://www.instagram.com/florianparfuss/" target="_blank"><FontAwesomeIcon icon={faFacebook} size={"9x"}/></a>
    </Col>
    <Col md={4} className=" d-flex justify-content-center align-items-center">
      <a href="https://at.linkedin.com/in/florian-parfuss-733221182" target="_blank"><FontAwesomeIcon icon={faLinkedin} size={"9x"}/></a>
    </Col>
  </Row>
)